import React, { createContext, useEffect, useState } from "react";
import { venuesInterface } from "../../interfaces/venues";
import { getVenueContext, saveVenueContext } from "../../utils/sessionStorage";

export interface VenueContextInterface {
  venueList: venuesInterface[];
  venue: venuesInterface | null;
  selectVenue: (venue: venuesInterface | null) => void;
  saveVenueList: (list: venuesInterface[]) => void;
  updateVenue: (venue: venuesInterface) => void;
  logoutVenue: () => void;
}

export const VenuesContext = createContext<VenueContextInterface>({
  venue: null,
  selectVenue: () => null,
  venueList: [],
  saveVenueList: () => null,
  updateVenue: () => null,
  logoutVenue: () => null,
});

interface ProviderInterface {
  children: React.ReactNode;
}

export default function VenueProvider({ children }: ProviderInterface) {
  const [venues, setVenues] = useState<venuesInterface[]>([]);
  const [venueSelected, setVenueSelected] = useState<venuesInterface | null>(
    null,
  );

  //reload de la pagina
  useEffect(() => {
    const previousContext = getVenueContext();
    if (previousContext !== null) {
      setVenueSelected(previousContext.venue);
      setVenues(previousContext.venueList);
    }
  }, []);

  //actualizar session storage
  useEffect(() => {
    saveVenueContext({
      venue: venueSelected,
      logoutVenue,
      saveVenueList: setVenues,
      selectVenue: setVenueSelected,
      updateVenue,
      venueList: venues,
    });
  }, [venueSelected, venues]);

  const updateVenue = (venue: venuesInterface) => {
    const newVenuesList = [...venues];
    const findIndex = newVenuesList.findIndex((i) => i.uuid === venue.uuid);
    newVenuesList.splice(findIndex, 1, venue);
    setVenues(newVenuesList);
    setVenueSelected(venue);
  };

  const logoutVenue = () => {
    setVenueSelected(null);
    setVenues([]);
  };

  return (
    <VenuesContext.Provider
      value={{
        venueList: venues,
        selectVenue: (venue: venuesInterface | null) => setVenueSelected(venue),
        venue: venueSelected,
        saveVenueList: (list: venuesInterface[]) => setVenues(list),
        updateVenue,
        logoutVenue,
      }}
    >
      {children}
    </VenuesContext.Provider>
  );
}
