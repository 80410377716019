import { AuthContextInterface } from "../App";
import { VenueContextInterface } from "../components/VenueProvider/VenueProvider";
import { concessionsInterface } from "../interfaces/concessions";
import { querie_object_interface } from "../interfaces/Core/Queries";

export const saveContext = async (
  context: AuthContextInterface,
): Promise<void> => {
  localStorage.setItem("context", JSON.stringify(context));
  return Promise.resolve();
};

export const getContext = (): AuthContextInterface | null => {
  const contextString = localStorage.getItem("context");

  if (contextString !== null) {
    const context = JSON.parse(contextString);
    return context;
  }

  return null;
};

export const saveConcessionSelected = (
  concession: concessionsInterface | null,
) => {
  localStorage.setItem("concession", JSON.stringify(concession));
};

export const getConcessionSelected = (): concessionsInterface | null => {
  const contextString = localStorage.getItem("concession");

  if (contextString !== null && contextString !== undefined) {
    const context = JSON.parse(contextString);
    return context;
  }

  return null;
};

export const saveVenueContext = (context: VenueContextInterface) => {
  localStorage.setItem("venueContext", JSON.stringify(context));
};

export const getVenueContext = (): VenueContextInterface | null => {
  const contextString = localStorage.getItem("venueContext");

  if (contextString !== null) {
    const context = JSON.parse(contextString);
    return context;
  }

  return null;
};

export const saveQuerieParams = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getQuerieParams = (
  key: string,
): querie_object_interface | null => {
  const querieValues = localStorage.getItem(key);

  if (querieValues !== null) {
    const value = JSON.parse(querieValues);

    return value;
  }
  return null;
};

export const changeUserSessionStorage = () => {
  for (const property in localStorage) {
    if (
      property !== "context" &&
      property !== "concession" &&
      property !== "venueContext"
    )
    localStorage.removeItem(property);
  }
};

export const saveHomeState = (value: string) => {
  localStorage.setItem("home", value);
};

export const getHomeState = () => {
  const homeState = localStorage.getItem("home");
  if (homeState !== null) {
    const value = JSON.parse(homeState);
    return value;
  }
  return null;
};

export const clearStorage = () => {
  localStorage.clear();
};
