import React from "react";

import { SpinnerCircularFixed } from "spinners-react";

const LoaderPage = () => {
  return (
    <div className="main-container-tail">
      <div className="fixed inset-0 z-[1000000] m-auto flex h-full w-full items-center justify-center bg-white/80 ">
        <div className="flex h-full flex-col items-center justify-center  gap-6 p-10  ">
          <SpinnerCircularFixed
            size={80}
            thickness={100}
            speed={250}
            color="rgba(215, 1, 90, 1)"
            secondaryColor="rgba(241, 241, 241, 1)"
          />
          <div className="flex flex-col items-center justify-center gap-2">
            <h3 className="text-2xl font-bold">Actualizando Datos...</h3>
            <span className="text-description">Descargando...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderPage;
