import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { createContext, useContext, useState } from "react";
import { AuthContext } from "../../App";
import { BusinessContext } from "../BusinessProvider/BusinessProvider";
import { SpinnerCircularFixed } from "spinners-react";

interface AlertModalInterface {
  showAlert: (t: string, type: "error" | "success" | "loading") => void;
  hideAlert: () => void;
}

export const ErrorModalContext = createContext<AlertModalInterface>({
  showAlert: () => null,
  hideAlert: () => null,
});

interface ModalInterface {
  children: React.ReactNode;
}

export default function AlertModal({ children }: ModalInterface) {
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState<"error" | "success" | "loading">("error");
  const { getLogout } = useContext(AuthContext);
  const { logoutConcession } = useContext(BusinessContext);

  const showAlert = (
    t: string,
    type: "error" | "success" | "loading" = "error",
  ) => {
    setShowModal(true);
    setText(t);
    setType(type);
    setTimeout(() => {
      hideAlert();
    }, 10000);
  };

  const hideAlert = () => {
    setShowModal(false);
    setText("");
    setType("error");
  };

  React.useEffect(() => {
    if (text === "sesión expirada") {
      getLogout();
      logoutConcession();
    }
  }, [text]);

  if (type === "error") {
    return (
      <ErrorModalContext.Provider value={{ showAlert, hideAlert }}>
        <div
          className={`absolute right-0  ${
            showModal ? "right-0 top-14" : "top-[-2000px] "
          }  z-[9999] m-4 flex w-auto max-w-md flex-row items-center gap-3 rounded-md bg-secondary p-4 text-white transition-all`}
        >
          <XMarkIcon
            className="h-5 w-5 cursor-pointer"
            onClick={hideAlert}
          />
          <span className="">{text}</span>
        </div>
        {children}
      </ErrorModalContext.Provider>
    );
  }

  if (type === "success") {
    return (
      <ErrorModalContext.Provider value={{ showAlert, hideAlert }}>
        <div
          className={`absolute right-0  ${
            showModal ? "right-0 top-14" : "top-[-2000px] "
          }  z-[9999] m-4 flex w-auto max-w-md flex-row items-center gap-3 rounded-md bg-success p-4 text-white transition-all`}
        >
          <XMarkIcon
            className="h-5 w-5 cursor-pointer"
            onClick={hideAlert}
          />
          <span className="">{text}</span>
        </div>
        {children}
      </ErrorModalContext.Provider>
    );
  }

  return (
    <ErrorModalContext.Provider value={{ showAlert, hideAlert }}>
      <div
        className={`absolute right-0  ${
          showModal ? "right-0 top-14" : "top-[-2000px] "
        }  z-[9999] m-4 flex w-auto max-w-md flex-row items-center gap-3 rounded-md  border border-gray-300 bg-white p-4 text-gray-800 shadow-md transition-all`}
      >
        <SpinnerCircularFixed
          size={10}
          thickness={100}
          speed={250}
          color="rgba(215, 1, 90, 1)"
          secondaryColor="rgba(241, 241, 241, 1)"
        />
        <span className="">{text}</span>
      </div>
      {children}
    </ErrorModalContext.Provider>
  );
}
