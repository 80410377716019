import React from "react";

import YoshiLogo from "../../../resources/logos/yoshiLight.svg";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

export default function RouteNotFound() {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-full flex-col items-center  justify-center gap-2">
      <img
        alt="yoshi logo"
        src={YoshiLogo}
        className="w-[50%]  md:w-[30%] lg:w-[15%]"
      />
      <h1 className="text-3xl font-bold text-primary">
        Ups! Parece que la ruta a la que quieres acceder, no se encuentra
        disponible
      </h1>

      <Button
        label="Página principal"
        type="secondary"
        onClick={() => {
          navigate("/panel/inicio");
        }}
      />
    </div>
  );
}
