/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import clsx from "clsx";
import { usePopper } from "react-popper";
import { RotateSpinner } from "react-spinners-kit";
import { Popover } from "@headlessui/react";
import ListSelect from "../ListSelect/ListSelect";

export interface ButtonInterface {
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  label: string;
  icon?: React.ElementType;
  type?: "primary" | "secondary" | "danger" | "success";
  full?: boolean;
  actions?: any[];
  handleActions?: (item: any) => void;
  submit?: boolean;
  onlyIcon?: boolean;
}

interface PopOver {
  open: boolean;
  close: VoidFunction;
}

const Button = ({
  onClick,
  className,
  loading,
  disabled,
  label,
  icon: Icon,
  type = "primary",
  full,
  actions,
  handleActions,
  submit = false,
  onlyIcon,
  ...props
}: ButtonInterface) => {
  // Reposition the popover based on available space
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const iconColors = {
    primary: "#FFFFFF",
    secondary: "#D7015A",
    danger: "#FFFFFF",
    success: "#ffffff",
  };

  return (
    <Popover className={full ? "relative w-full" : "relative"}>
      {({ open, close }: PopOver) => (
        <>
          <Popover.Button
            as="div"
            ref={setReferenceElement}
            className={full ? "w-full outline-none" : "outline-none"}
          >
            <button
              type={submit ? "submit" : "button"}
              disabled={disabled}
              onClick={onClick}
              className={clsx(
                "min-h-[41px] min-w-[80px] rounded-lg  px-4 py-2 transition-colors duration-300",
                "text-md font-camphor font-semibold focus:outline-none disabled:cursor-not-allowed disabled:opacity-50",
                full && "w-full",
                type === "primary" &&
                  "text bg-primary text-white hover:bg-primary-hover active:bg-primary",
                type === "secondary" &&
                  "font-camphor min-h-[41px] min-w-[80px] rounded-lg border border-default bg-actions px-4 py-2 text-sm font-semibold text-black transition-colors duration-300 hover:bg-actions-hover focus:outline-none active:bg-actions disabled:cursor-not-allowed disabled:opacity-50",
                type === "success" &&
                  "bg-success text-white hover:bg-success-hover active:bg-success",
                type === "danger" &&
                  "bg-danger text-white hover:bg-danger-hover active:bg-danger",
                className,
              )}
              {...props}
            >
              <div className="flex items-center justify-center gap-1">
                {loading && (
                  <div className="absolute">
                    <RotateSpinner
                      size={20}
                      color={iconColors[type]}
                      loading={loading}
                    />
                  </div>
                )}
                {Icon && (
                  <Icon
                    className={clsx(
                      "h-4 w-4 stroke-[2.5]",
                      type === "primary" && "stroke-white",
                      type === "secondary" && "stroke-primary",
                      type === "danger" && "stroke-white",
                      loading && "opacity-0",
                    )}
                  />
                )}
                <span className={`${loading && "opacity-0"} ${onlyIcon && "hidden md:block"}`}>{label}</span>
              </div>
            </button>
          </Popover.Button>
          {actions && (
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-[1000] w-screen max-w-max"
              onClick={close}
            >
              <ListSelect
                open={open && actions.length > 0}
                items={actions}
                onChange={handleActions}
              />
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  );
};

export default Button;
