/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import clsx from "clsx";

interface ListSelectProps {
  open: boolean;
  items: any[];
  onChange?: (item: any) => void;
}

const ListSelect = ({ open, items, onChange }: ListSelectProps) => {
  const selectItem = (item: any) => {
    onChange && onChange(item);
  };

  const data = items.map((item: any, i: number) => {
    return (
      <li
        key={i}
        className={clsx(
          "z-[300] rounded-lg bg-white px-3 py-2 hover:cursor-pointer",
          "transition-colors hover:border-none hover:outline-none",
          "text-left",
          item.type !== "danger"
            ? "hover:bg-primary/10 hover:text-primary"
            : "hover:bg-danger/10 hover:text-danger",
        )}
        onClick={() => selectItem(item)}
      >
        {item.name}
      </li>
    );
  });

  return open ? (
    <div
      className="font-camphor z-[300] mt-2 max-h-[700px] min-w-[110px] overflow-y-auto 
			  rounded-lg border border-default bg-white text-sm font-medium text-dark"
    >
      <ul className="list-none px-1 py-[5px] hover:z-[300]">{data}</ul>
    </div>
  ) : null;
};

export default ListSelect;
