import React, { createContext, useEffect, useState } from "react";
import { concessionsInterface } from "../../interfaces/concessions";
import {
  getConcessionSelected,
  saveConcessionSelected,
} from "../../utils/sessionStorage";

interface BusinessContextInterface {
  businessList: concessionsInterface[];
  business: concessionsInterface | null;
  selectBusiness: (bs: concessionsInterface | null) => void;
  saveList: (list: concessionsInterface[]) => void;
  updateConcession: (c: concessionsInterface) => void;
  logoutConcession: () => void;
}

export const BusinessContext = createContext<BusinessContextInterface>({
  business: null,
  selectBusiness: () => null,
  businessList: [],
  saveList: () => null,
  updateConcession: () => null,
  logoutConcession: () => null,
});

interface ProviderInterface {
  children: React.ReactNode;
}

export default function BusinessProvider({ children }: ProviderInterface) {
  const [business, setBusiness] = useState<concessionsInterface[]>([]);
  const [businessSelected, setBusinessSelected] =
    useState<concessionsInterface | null>(null);

  //reload de la pagina
  useEffect(() => {
    const previousConcession = getConcessionSelected();
    if (previousConcession !== null && previousConcession !== undefined) {
      setBusinessSelected(previousConcession);
    }
  }, []);

  //actualizar session storage
  useEffect(() => {
    saveConcessionSelected(businessSelected);
  }, [businessSelected]);

  const updateConcession = (c: concessionsInterface) => {
    const newBusinessList = [...business];
    const findIndex = newBusinessList.findIndex((i) => i.uuid === c.uuid);
    newBusinessList.splice(findIndex, 1, c);
    setBusiness(newBusinessList);
    setBusinessSelected(c);
  };

  const logoutConcession = () => {
    setBusinessSelected(null);
    setBusiness([]);
  };

  return (
    <BusinessContext.Provider
      value={{
        businessList: business,
        selectBusiness: (bs: concessionsInterface | null) =>
          setBusinessSelected(bs),
        business: businessSelected,
        saveList: (list: concessionsInterface[]) => setBusiness(list),
        updateConcession,
        logoutConcession,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
}
