import React, { createContext, Suspense, useEffect, useState } from "react";
import "./App.css";

import LoaderPage from "./components/LoaderPage/LoaderPage";
import { userInformation } from "./interfaces/users";
import { getContext, saveContext } from "./utils/sessionStorage";
import RouterProv from "./router";
import AlertModal from "./components/AlertsModal/AlertsModal";
import BusinessProvider from "./components/BusinessProvider/BusinessProvider";
import VenueProvider from "./components/VenueProvider/VenueProvider";
import ErrorBoundary from "./components/Core/ErrorBoundary/ErrorBoundary";

export interface AuthContextInterface {
  user: userInformation | null;
  isLogged: boolean;
  login: (user: userInformation) => void;
  getLogout: () => void;
  setProfileId: (n: number) => void;
  viewType: "venue" | "concession" | "pos";
  changeViewType: (s: "venue" | "concession" | "pos") => void;
}
interface AuthContextInformation {
  user: userInformation | null;
  isLogged: boolean;
  viewType: "venue" | "concession" | "pos";
}
export const AuthContext = createContext<AuthContextInterface>({
  user: {
    token: "",
    uuid_user: "",
    id_profile: 0,
  },
  isLogged: false,
  login: () => null,
  getLogout: () => null,
  setProfileId: () => null,
  viewType: "venue",
  changeViewType: () => null,
});

function App() {
  const [contextInfo, setContextInfo] = useState<AuthContextInformation>({
    isLogged: false,
    user: null,
    viewType: "venue",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const changeViewType = (s: "venue" | "concession" | "pos") => {
    setContextInfo({
      ...contextInfo,
      viewType: s,
    });
  };

  //reload de la pagina
  useEffect(() => {
    const previousContext = getContext();
    if (previousContext !== null) {
      setContextInfo({
        user: previousContext.user,
        viewType: previousContext.viewType,
        isLogged: previousContext.isLogged,
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const updateContext = async () => {
    await saveContext({
      user: contextInfo.user,
      isLogged: contextInfo.isLogged,
      login: getLogin,
      getLogout,
      setProfileId,
      viewType: contextInfo.viewType,
      changeViewType,
    });
    setIsLoading(false);
  };

  //actualizar session Storage
  useEffect(() => {
    updateContext();
  }, [contextInfo]);

  const setProfileId = (n: number) => {
    if (contextInfo.user) {
      setContextInfo({
        ...contextInfo,
        user: {
          ...contextInfo.user,
          id_profile: n,
        },
      });
    }
  };

  const getLogin = (user: userInformation) => {
    setContextInfo({
      ...contextInfo,
      user: user,
      isLogged: true,
    });
  };

  const getLogout = () => {
    setContextInfo({
      user: null,
      isLogged: false,
      viewType: "venue",
    });
  };

  if (isLoading) {
    return <LoaderPage />;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoaderPage />}>
        <AuthContext.Provider
          value={{
            user: contextInfo.user,
            isLogged: contextInfo.isLogged,
            login: getLogin,
            getLogout,
            setProfileId,
            viewType: contextInfo.viewType,
            changeViewType,
          }}
        >
          <BusinessProvider>
            <VenueProvider>
              <AlertModal>
                <RouterProv />
              </AlertModal>
            </VenueProvider>
          </BusinessProvider>
        </AuthContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
