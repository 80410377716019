import React, { Component, ReactNode } from "react";
import YoshiLogo from "../../../resources/logos/yoshiLight.svg";
import Button from "../../Button/Button";
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  // Este método actualiza el estado si ocurre un error
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  // Este método registra detalles del error
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Renderizamos una UI de respaldo
      return (
        <div className="flex h-screen w-full flex-col items-center  justify-center gap-2">
          <img
            alt="yoshi logo"
            src={YoshiLogo}
            className="w-[50%]  md:w-[30%] lg:w-[15%]"
          />
          <h1 className="text-3xl font-bold text-primary">
            Ups! Ha ocurrido un error en la aplicación
          </h1>
          <p className="text-sm font-bold text-primary-hover">
            {`Si el error persiste, favor de comunicarse con soporte técnico`}
          </p>
          <p className="text-sm font-bold text-description">
            {`"${
              this.state.error?.message || "An unexpected error occurred."
            }"`}
          </p>
          <Button
            label="Volver a Página de Inicio"
            type="secondary"
            onClick={() => {
              this.setState({ hasError: false, error: null });
              handleRedirect();
            }}
          />
        </div>
      );
    }

    // Si no hay error, renderizamos los hijos normalmente
    return this.props.children;
  }
}

const handleRedirect = () => {
  window.location.href = "/"; // Redirige a la ruta interna
};

export default ErrorBoundary;
